import { StaticImage } from "gatsby-plugin-image";
import React from "react";

export const HeaderImage = () => {
  return (
    <StaticImage
      alt={process.env.COMPANY_NAME_SHORT}
      className="m-auto"
      height={208 / 4}
      src="../../images/logo-black.png"
      width={720 / 4}
    />
  );
};
