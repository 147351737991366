const zipCodes = [
  80002, 80003, 80004, 80005, 80007, 80010, 80011, 80012, 80013, 80014, 80015,
  80016, 80017, 80018, 80019, 80020, 80021, 80022, 80023, 80024, 80026, 80027,
  80030, 80031, 80033, 80035, 80036, 80040, 80042, 80045, 80104, 80106, 80107,
  80108, 80109, 80110, 80111, 80112, 80113, 80116, 80118, 80120, 80121, 80122,
  80123, 80124, 80125, 80126, 80127, 80128, 80129, 80130, 80132, 80133, 80134,
  80135, 80138, 80202, 80203, 80204, 80205, 80206, 80207, 80209, 80210, 80211,
  80212, 80214, 80215, 80218, 80219, 80220, 80221, 80222, 80223, 80224, 80226,
  80227, 80228, 80229, 80230, 80231, 80232, 80233, 80234, 80235, 80236, 80237,
  80238, 80239, 80241, 80246, 80247, 80249, 80260, 80301, 80302, 80303, 80304,
  80305, 80306, 80307, 80309, 80310, 80314, 80401, 80403, 80465, 80501, 80502,
  80503, 80504, 80513, 80514, 80516, 80520, 80530, 80601, 80602, 80603, 80621,
  80640, 80903, 80904, 80905, 80906, 80907, 80908, 80909, 80910, 80911, 80913,
  80915, 80916, 80917, 80918, 80919, 80920, 80921, 80922, 80923, 80924, 80925,
  80927, 80951,
];

const extendedZipCodes = [
  80521, 80524, 80525, 80526, 80528, 80534, 80537, 80538, 80542, 80543, 80547,
  80550, 80620, 80631, 80634, 80642, 80651, 80817, 80818, 80831,
];

const zipCodesSecondary = [
  60411, 60412, 60076, 60077, 60201, 60202, 60203, 60204, 60208, 60209, 60004,
  60005, 60006, 60016, 60017, 60018, 60019, 60159, 60168, 60173, 60193, 60194,
  60195, 60196, 60025, 60026, 60301, 60302, 60303, 60304, 60008, 60153, 60067,
  60074, 60078, 60094, 60095, 60455, 60428, 60402, 60804, 60453, 60454, 60062,
  60065, 60091, 60406, 60169, 60179, 60192, 60068, 60714, 60056, 60452, 60525,
  60093, 60402, 60018, 60419, 60409, 60154, 60707, 60443, 60411, 60131, 60429,
  60160, 60161, 60164, 60305, 60130, 60162, 60090, 60022, 60430, 60803, 60426,
  60053, 60473, 60558, 60463, 60805, 60104, 60115, 60178, 60112, 60135, 60550,
  60556, 60150, 60520, 60145, 60146, 60146, 60187, 60189, 60515, 60516, 60126,
  60148, 60137, 60188, 60101, 60185, 60186, 60523, 60532, 60190, 60559, 60108,
  60181, 60139, 60555, 60527, 60561, 60143, 60191, 60514, 60181, 60519, 60450,
  60416, 60444, 60424, 60474, 60416, 60479, 60474, 60437, 60134, 60510, 60110,
  60119, 60177, 60140, 60542, 60118, 60140, 60175, 60109, 60554, 60136, 60511,
  60118, 60144, 60151, 60182, 60554, 60120, 60560, 60545, 60543, 60538, 60541,
  60560, 60541, 60536, 60079, 60085, 60087, 60031, 60073, 60030, 60048, 60073,
  60060, 60045, 60002, 60061, 60047, 60035, 60037, 60099, 60046, 60064, 60084,
  60044, 60046, 60069, 60087, 60047, 60020, 60010, 60096, 60083, 60040, 60047,
  60073, 60073, 60030, 60030, 60047, 60010, 60015, 60030, 60085, 60015, 60048,
  60041, 60045, 60010, 60010, 60083, 60047, 60002, 60020, 60031, 60061, 60046,
  60002, 60044, 60050, 60051, 60012, 60014, 60098, 60013, 60033, 60156, 60152,
  60051, 60097, 60081, 60098, 60071, 60014, 60034, 60012, 60180, 60072, 60050,
  60013, 60051, 60013, 60051, 60071, 60152, 60098, 60152, 60012, 60098, 60097,
  60098, 60001, 60033, 60451, 60441, 60446, 60448, 60404, 60491, 60449, 60417,
  60403, 60481, 60468, 60401, 60421, 60442, 60436, 60442, 60481, 60423, 60431,
  60448, 60481, 60417, 60433, 60417, 60481, 60435, 60481, 60401, 60432, 60468,
  60433, 53140, 53142, 53143, 53144, 53158, 53168, 53170, 53192, 53179, 53144,
  53168, 53181, 53105, 53168, 53144, 53105, 53152, 53159, 53105, 60043, 60070,
  60089, 60101, 60103, 60126, 60137, 60148, 60157, 60172, 60174, 60184, 60185,
  60187, 60188, 60189, 60190, 60502, 60504, 60515, 60516, 60517, 60521, 60523,
  60526, 60532, 60540, 60559, 60563, 60564, 60565,
];

const extendedZipCodesSecondary = [
  61008, 61065, 61012, 61038, 61011, 61008, 60901, 60914, 60915, 60950, 60954,
  60964, 60940, 60910, 60941, 60944, 60913, 60901, 60935, 60964, 60969, 60901,
  60917, 60901, 61301, 61350, 61348, 61373, 61341, 61342, 61370, 60518, 61334,
  61325, 60551, 61372, 61316, 60531, 61350, 61332, 60557, 60549, 60552, 61364,
  60470, 61350, 61358, 60551, 61321, 61021, 61310, 61031, 61367, 61006, 61353,
  61331, 61057, 61378, 61318, 61042, 60553, 61058, 61764, 61739, 61769, 60921,
  60460, 61740, 60929, 61741, 60934, 61319, 61333, 61775, 61319, 60920, 61061,
  61054, 61010, 61084, 61030, 61047, 61064, 61020, 61052, 61021, 60113, 61049,
  61047, 61068, 61043, 61068, 61101, 61102, 61103, 61104, 61107, 61108, 61109,
  61114, 61088, 61073, 61072, 61063, 61080, 61115, 61024, 61109, 61077, 46350,
  46352, 46360, 46361, 46391, 46371, 46390, 46346, 46345, 46360, 46360, 46348,
  46552, 46360, 46340, 46574, 46360, 46307, 46308, 46401, 46402, 46403, 46404,
  46405, 46406, 46407, 46408, 46409, 46410, 46411, 46320, 46321, 46322, 46323,
  46324, 46325, 46327, 46410, 46411, 46321, 46303, 46375, 46342, 46322, 46311,
  46373, 46312, 46356, 46319, 46394, 46405, 46307, 46342, 46356, 46377, 46376,
  46383, 46384, 46385, 46304, 46368, 46304, 46347, 46341, 46304, 46301, 46302,
  46385, 46368, 46304, 46393, 46360, 46385, 46385, 46385, 53202, 53203, 53233,
  53205, 53206, 53209, 53210, 53216, 53218, 53222, 53223, 53224, 53225, 53204,
  53207, 53215, 53221, 53234, 53208, 53213, 53214, 53226, 53211, 53212, 53219,
  53227, 53228, 53235, 53214, 53219, 53227, 53213, 53222, 53225, 53226, 53154,
  53132, 53219, 53220, 53221, 53227, 53132, 53219, 53220, 53221, 53227, 53219,
  53220, 53221, 53227, 53209, 53217, 53211, 53110, 53223, 53235, 53172, 53129,
  53217, 53217, 53217, 53214, 53130, 53402, 53403, 53404, 53405, 53406, 53403,
  53404, 53405, 53406, 53182, 53108, 53402, 53404, 53405, 53406, 53177, 53185,
  53402, 53167, 53185, 53126, 53177, 53405, 53139, 53185, 53105, 53402, 53105,
  53545, 53546, 53548, 53511, 53563, 53536, 53576, 53525, 53537, 53534, 53536,
  53545, 53511, 53525, 53536, 53542, 53184, 53121, 53147, 53115, 53120, 53191,
  53125, 53114, 53585, 53120, 53105, 53115, 53121, 53105, 53121, 53147, 53115,
  54889, 53120, 53147, 53115, 53121, 53120, 53176, 53186, 53188, 53189, 53072,
  53186, 53005, 53045, 53066, 53069, 53146, 53151, 53051, 53052, 53150, 53029,
  53018, 53089, 53122, 53058, 53066, 53103, 53119, 53103, 53072, 53118, 53183,
  53153, 53046, 53069, 53029, 53056, 53066, 53007, 53064, 53118,
];

export {
  extendedZipCodes,
  extendedZipCodesSecondary,
  zipCodes,
  zipCodesSecondary,
};
